<template>
  <div class="wrapper" v-click-outside="focusOut">
    <FloatLabel :label="label" :optional="optional" :class="`${selectDisabled ? 'float-label' : ''}`" :ask="isNZ" :tooltip="locationTooltipContent">
      <div 
        :class="[`${error ? 'invalid' : ''}`,`${selectDisabled ? 'disabled' : ''}`,]" 
        class="input-wrapper d-flex justify-content-between align-items-center"
      >
        <input
          class="shadow-none form-control autocomplete-custom"
          v-model.trim="inputValue"
          :name="name"
          :placeholder="placeholder"
          autocomplete="off"
          ref="autocomplete"
          :class="[`${error ? 'invalid' : ''}`]"
          @focus="initAutocomplete"
          required
          :disabled="selectDisabled"
          @input="handleOnInput"
        />
      </div>
    </FloatLabel>
    <div v-html="error ? helperText : ''" class="fs-12 text-left mb-2 mt-n3 pr-2 err-text"></div>
  </div>
</template>

<script>
import { logError } from "@/helper/utils";
export default {
  name: 'GooglePlaceAutocomplete',
  components: { 
   FloatLabel: () => import('./FloatLabel.vue')
  },
  model: {
    prop: 'selectValue',
    event: 'change'
  },
  data() {
    return {
      autocomplete: '',
      inputValue: this.selectValue,
      locationTooltipContent: "Full address is required to determine the correct suburb and region. AutoFlip only stores your suburb and region.",
    }
  },
  props: {
    selectValue: {
      type: [Number, String],
      default: () => ""
    },
    label: String,
    name: {
      type: String,
      default: () => ""
    },
    isNZ: {
      type: Boolean,
      default: () => false
    },
    error: Boolean,
    isFocus: Boolean,
    optional: Boolean,
    placeholder: String,
    helperText: String,
    selectDisabled: Boolean,
    isMobile: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    initAutocomplete() {
      this.autocomplete = new window.google.maps.places.Autocomplete(this.$refs['autocomplete'], {
        componentRestrictions: {
          country: this.isNZ ? ['nz'] : ['au'],
        },
        fields: ['address_components'],
        types: this.isNZ
            ? ["geocode", "establishment"]
            : [
                "locality",
                "street_address",
                "postal_code",
                "postal_code_prefix",
              ],
        // types: ['geocode', 'establishment'],
        // fields: ["address_components", "geometry"],
        // types: ["address"],
      });
      this.autocomplete.addListener('place_changed', this.fillInAddress);
    },
    fillInAddress() {
      try {
        const place = this.autocomplete.getPlace();

        if (place?.address_components) {
          /**
           * For AU administrativeArea is State
           * For NZ administrativeArea is Region
           */
          const administrativeArea = place.address_components.find(
            (component) =>
              [
                'administrative_area_level_1',
              ].some((type) => component.types.includes(type))
          );

          const auSuburb = !this.isNZ 
            ? place.address_components.find((component) =>
              component.types.includes('locality')
            )
            : {};
          const nzSuburb = this.isNZ
            ? place.address_components.find((component) =>
                ['locality', 'sublocality_level_1', 'sublocality'].some(
                  (type) => component.types.includes(type)
                )
              )
            : {};

            const postalCode = !this.isNZ
            ? place.address_components.find((component) =>
              component.types.includes('postal_code')
            )
            : {};
  
          let address = Array.from(
            new Set([
              nzSuburb?.long_name,
              auSuburb?.long_name,
              administrativeArea?.short_name,
              postalCode?.long_name,
            ])
          )
            .filter(Boolean)
            .join(' ');
  
          this.inputValue = address;
          this.focusOut();
        } else {
          this.inputValue = this.$refs['autocomplete'].value.replace(
            this.isNZ ? ', New Zealand' : ', Australia',
            ''
          );
          this.focusOut();
        }
      } catch (error) {
        logError(`Google place API error: ${error}`);
      }
    },
    focusOut() {
      this.$emit('change', this.inputValue);
    },
    handleOnInput() {
      this.$emit('input', this.inputValue);
    },
    hoverTooltip: function() {
      if (!this.isMobile) {
        this.$refs.locationTooltip.style.visibility = "visible";
      }
    },
    blurTooltip: function() {
      this.$refs.locationTooltip.style.visibility = "hidden";
    },
    handleMobileTap: function(event) {
      event.stopPropagation();
      event.preventDefault();
      if (this.isMobile) {
        this.$refs.locationTooltip.style.visibility = "visible";
      }
    },
  },
  mounted() {
    this.initAutocomplete();
  },
}
</script>
<style lang="scss">
.pac-container {
  z-index: 100002;
  box-shadow: 0px 0px 1px 0px #bebebe !important;
}
.pac-item {
  padding: 5px;
  cursor: pointer;
}
.location-tooltip {
  position: absolute;
  bottom: 40px;
  right: -10px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #2E312E;
  color: white;
  font-size: 12px;
  text-align: left;
  width: 224px;
  visibility: hidden;
  z-index: 10;
  p {
    margin-top: 0 !important;
  }
}
.location-tooltip-icon {
  position: absolute;
  width: 20px !important; 
  height: 20px !important; 
  z-index: 1;
  right: 9px;
  cursor: pointer;
}
@media screen and(min-width: 900px) {
  .location-tooltip {
    width: 274px !important;
  }
  .location-tooltip-icon {
    right: 12px !important;
  }
}
@media screen and(min-width: 1200px) {
  .location-tooltip-icon {
    right: 13px !important;
  }
}
</style>
