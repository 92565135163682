<template>
  <div>
    <h6 class="font-weight-bold fw-400 toggle-label">
      {{ label }}
      <span>
        <img
          v-if="infoTooltip"
          class="info-mark"
          :src="require(`@/assets/images/icons/ic_question_mark_black_filled.svg`)"
          alt="info-mark"
          @click="toggleTooltip"
          v-on:mouseenter="hoverTooltip"
          v-on:mouseleave="blurTooltip"
        />
      </span>
    </h6>
    <div
      ref="floatLabelTooltip"
      class="float-label-finance-tooltip shadow-lg font-weight-thin fs-12"
    >
      <p class="mb-0">{{ tooltip }}</p>
    </div>
    <div
      class="answer-group d-flex ml-3 mb-3"
      :class="[`${checkReadonly ? 'disabled' : ''}`, `${error ? 'invalid' : ''}`]"
    >
      <span 
        class="answer mr-1" 
        @click="handleChooseOption('Yes')"
        :class="{ 'answer-active': toggleValue === 'Yes' }"
      >Yes</span>
      <span 
        class="answer" 
        @click="handleChooseOption('No')"
        :class="{ 'answer-active': toggleValue === 'No' }"
      >No</span>
    </div>
    <div v-if="error" class="fs-12 text-right mb-2 mt-n3 pr-2 err-text">{{ 'Please make a selection' }}</div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "CustomToggle",
  data() {
    return {
      isMobile: true,
    }
  },
  model: {
    prop: "toggleValue",
    event: "change",
  },
  props: {
    toggleValue: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
    error: {
      type: Boolean,
      default: () => false,
    },
    infoTooltip: {
      type: Boolean,
      default: () => false,
    },
    tooltip: {
      type: String,
      default: () => "",
    },
  },
  computed: {
    ...mapState({
      authenticateData: (state) => state.authenticateSeller,
    }),
    checkReadonly: function() {
      return this.authenticateData.listed || this.authenticateData.readonlyMode;
    },
    mobileMediaQuery: function() {
      return window.matchMedia("(min-width: 768px)");
    },
    haveTooltip: function() {
      return Object.keys(this.tooltip).length !== 0
    },
  },
  methods: {
    handleChooseOption(val) {
      if (!this.checkReadonly) {
        this.$emit("change", val);
      }
    },
    toggleTooltip: function(e) {
      e.preventDefault();
      e.stopPropagation();
      if (this.haveTooltip && this.isMobile) {
        const currentVisibility = this.$refs.floatLabelTooltip.style.visibility;
        this.$refs.floatLabelTooltip.style.visibility =
          currentVisibility === "visible" ? "hidden" : "visible";
      }
    },
    blurTooltip: function() {
      if (this.haveTooltip) {
        this.$refs.floatLabelTooltip.style.visibility = "hidden";
      }
    },
    hoverTooltip: function() {
      if (this.haveTooltip && !this.isMobile) {
        this.$refs.floatLabelTooltip.style.visibility = "visible";
      }
    },
  },
  mounted() {
    // Check mobile screen
    this.mobileMediaQuery.addListener((e) => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });
    this.isMobile = !this.mobileMediaQuery.matches;
  },
};
</script>
<style lang="scss">
.answer-group {
  border: 1px solid transparent !important;
  .answer {
    color: var(--primary-color);
    font-size: 16px;
    padding: 5px 10px;
    border: 1px solid #79747e;
    border-radius: 100px;
    min-width: 132px;
    min-height: 38px;
    cursor: pointer;
    text-align: center;
  }
  .answer-active {
    color: #FFFFFF;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
	}
  &.disabled {
    .answer {
      cursor: default;
    }
  }
  &.invalid {
    .answer {
      border-color: red;
    }
  }
}
.float-label-finance-tooltip {
  width: 252px;
  position: absolute;
  bottom: 85px;
  left: 245px;
  padding: 10px;
  border-radius: 4px;
  background-color: #2E312E;
  color: white;
  text-align: left;
  visibility: hidden;
  z-index: 10;
}
.info-mark {
  margin-top: -4px;
}
@media screen and(min-width: 768px) {
  .info-mark {
    cursor: pointer;
  }
}
@media screen and(max-width: 991px) {
  .float-label-finance-tooltip {
    left: 55px;
  }
}
@media screen and(max-width: 768px) {
  .float-label-finance-tooltip {
    left: 245px;
  }
}
@media screen and(max-width: 510px) {
  .float-label-finance-tooltip {
    left: 162px;
  }
}
@media screen and(max-width: 510px) {
  .float-label-finance-tooltip {
    left: 140px;
  }
}
@media screen and(max-width: 400px) {
  .float-label-finance-tooltip {
    left: 50px;
  }
  .toggle-label {
    width: 308px;
  }
}
@media screen and(min-width: 401px) and(max-width: 421px) {
  .toggle-label {
    width: 345px;
  }
  .float-label-finance-tooltip {
    left: 42px !important;
  }
}
</style>
