export const ACTION_AUTHENTICATE_SELLER_SUCCESS = 'ACTION_AUTHENTICATE_SELLER_SUCCESS'
export const SET_CAR_INFO_SUCCESS = 'SET_CAR_INFO_SUCCESS'
export const SET_SALE_INFORMATION_SUCCESS = 'SET_SALE_INFORMATION_SUCCESS'
export const SET_CONDITION_INFO_SUCCESS = 'SET_CONDITION_INFO_SUCCESS'
export const CLEAR_SERVICE_HISTORY_DETAIL_SUCCESS = 'CLEAR_SERVICE_HISTORY_DETAIL_SUCCESS'
export const CLEAR_SALE_INFO_OPTION_SUCCESS = 'CLEAR_SALE_INFO_OPTION_SUCCESS'
export const CLEAR_INFO_SUCCESS = 'CLEAR_INFO_SUCCESS'
export const CLEAR_INFO_EXTEND_SUCCESS = 'CLEAR_INFO_EXTEND_SUCCESS'
export const CLEAR_INFO_MODEL_SUCCESS = 'CLEAR_INFO_MODEL_SUCCESS'
export const CLEAR_INFO_YEAR_SUCCESS = 'CLEAR_INFO_YEAR_SUCCESS'
export const CLEAR_INFO_BODY_TYPES_SUCCESS = 'CLEAR_INFO_BODY_TYPES_SUCCESS'
export const CLEAR_INFO_FUEL_TYPES_SUCCESS = 'CLEAR_INFO_FUEL_TYPES_SUCCESS'
export const CLEAR_INFO_DRIVE_TYPES_SUCCESS = 'CLEAR_INFO_DRIVE_TYPES_SUCCESS'
export const CLEAR_INFO_TRANS_SUCCESS = 'CLEAR_INFO_TRANS_SUCCESS'
export const CLEAR_INFO_SERIES_SUCCESS = 'CLEAR_INFO_SERIES_SUCCESS'
export const CLEAR_INFO_ENGINE_TYPES_SUCCESS = 'CLEAR_INFO_ENGINE_TYPES_SUCCESS'
export const CLEAR_AUTHENTICATE_DATA_SUCCESS = 'CLEAR_AUTHENTICATE_DATA_SUCCESS'
export const UPDATE_REGO_AND_STATE_SUCCESS = 'UPDATE_REGO_AND_STATE_SUCCESS'
export const REGO_RE_LOOKUP_SUCCESS = 'REGO_RE_LOOKUP_SUCCESS'
export const CLEAR_SERVICE_HISTORY_NOTES_SUCCESS = 'CLEAR_SERVICE_HISTORY_NOTES_SUCCESS'
export const SET_WRITTEN_OFF_AND_STOLEN_SUCCESS = 'SET_WRITTEN_OFF_AND_STOLEN_SUCCESS'
export const CLEAR_INFO_ENGINE_SIZES_SUCCESS = 'CLEAR_INFO_ENGINE_SIZES_SUCCESS'
export const CLEAR_INFO_NUMBER_OF_SEATS_SUCCESS = 'CLEAR_INFO_NUMBER_OF_SEATS_SUCCESS'
export const REGO_ADDITIONAL_LOOKUP_SUCCESS = 'REGO_ADDITIONAL_LOOKUP_SUCCESS'
export const SET_LOCATION_ID_SUCCESS = 'SET_LOCATION_ID_SUCCESS'
export const SET_GUIDED_CAMERA_MOBILE_NUMBER_SUCCESS = 'SET_GUIDED_CAMERA_MOBILE_NUMBER_SUCCESS'
export const SET_GUIDED_CAMERA_SMS_URL_SUCCESS = 'SET_GUIDED_CAMERA_SMS_URL_SUCCESS'
export const SET_DELETED_IMAGES_SUCCESS = 'SET_DELETED_IMAGES_SUCCESS'
export const SET_PRODUCT_TIER_SUCCESS = 'SET_PRODUCT_TIER_SUCCESS'
export const SET_ADDON_OPTION_SUCCESS = 'SET_ADDON_OPTION_SUCCESS'
