export const ACTION_AUTHENTICATE_SELLER = 'ACTION_AUTHENTICATE_SELLER'
export const SET_SALE_INFORMATION = 'SET_SALE_INFORMATION'
export const SET_CONDITION_INFO = 'SET_CONDITION_INFO'
export const CLEAR_SERVICE_HISTORY_DETAIL = 'CLEAR_SERVICE_HISTORY_DETAIL'
export const CLEAR_SALE_INFO_OPTION = 'CLEAR_SALE_INFO_OPTION'
export const SET_CAR_INFO = 'SET_CAR_INFO'
export const CLEAR_INFO = 'CLEAR_INFO'
export const CLEAR_INFO_EXTEND = 'CLEAR_INFO_EXTEND'
export const CLEAR_INFO_MODEL = 'CLEAR_INFO_MODEL'
export const CLEAR_INFO_YEAR = 'CLEAR_INFO_YEAR'
export const CLEAR_INFO_BODY_TYPES = 'CLEAR_INFO_BODY_TYPES'
export const CLEAR_INFO_FUEL_TYPES = 'CLEAR_INFO_FUEL_TYPES'
export const CLEAR_INFO_DRIVE_TYPES = 'CLEAR_INFO_DRIVE_TYPES'
export const CLEAR_INFO_TRANS = 'CLEAR_INFO_TRANS'
export const CLEAR_INFO_SERIES = 'CLEAR_INFO_SERIES'
export const CLEAR_INFO_ENGINE_TYPES = 'CLEAR_INFO_ENGINE_TYPES'
export const CLEAR_AUTHENTICATE_DATA = 'CLEAR_AUTHENTICATE_DATA'
export const UPDATE_REGO_AND_STATE = 'UPDATE_REGO_AND_STATE'
export const REGO_RE_LOOKUP = 'REGO_RE_LOOKUP'
export const UPDATE_DATA = 'UPDATE_DATA'
export const CLEAR_SERVICE_HISTORY_NOTES = 'CLEAR_SERVICE_HISTORY_NOTES'
export const SET_WRITTEN_OFF_AND_STOLEN = 'SET_WRITTEN_OFF_AND_STOLEN'
export const CLEAR_INFO_ENGINE_SIZES = 'CLEAR_INFO_ENGINE_SIZES'
export const CLEAR_INFO_NUMBER_OF_SEATS = 'CLEAR_INFO_NUMBER_OF_SEATS'
export const REGO_ADDITIONAL_LOOKUP = 'REGO_ADDITIONAL_LOOKUP'
export const SET_LOCATION_ID = 'SET_LOCATION_ID'
export const SAVE_ACTION_LOG = 'SAVE_ACTION_LOG'
export const SET_GUIDED_CAMERA_MOBILE_NUMBER = 'SET_GUIDED_CAMERA_MOBILE_NUMBER'
export const SET_GUIDED_CAMERA_SMS_URL = 'SET_GUIDED_CAMERA_SMS_URL'
export const SET_DELETED_IMAGES = 'SET_DELETED_IMAGES'
export const SET_PRODUCT_TIER = 'SET_PRODUCT_TIER'
export const SET_ADDON_OPTION = 'SET_ADDON_OPTION'
