<template>
  <div class="content">
    <div>
      <div class="car-condition mt-3">
        <custom-dialog :isDialogOpen="isDialogOpen" @close="closeDialog" @save="saveOrUpdateTempData"/>
        <reminder-popup :isReminderOpen="isReminderOpen" @close="closeReminder"/>
        <ListedCarMessage />
        <b-card class="px-2 pb-4 rounded-2 mb-4 pt-0">
          <div class="w-100 center mb-4 base-width">
            <h1
              class="font-weight-bold text-left fs-20 content-title"
              style="margin-bottom: 0.5rem;"
            >
              CONDITION
            </h1>
            <SectionDivider dividerText="GENERAL"></SectionDivider>
            <OrderedComponents>
              <template v-slot:[generalConditionOrder.odometer]>
                <div class="px-3 col-md-6">
                  <float-label label="Odometer" :class="`${checkReadonly ? 'float-label' : ''}`">
                    <b-input
                      v-model="info.kms"
                      ref="kmsInput"
                      placeholder="Enter kms reading"
                      pattern="\d*"
                      :class="[
                        kmsValidate ? 'invalid' : '',
                        curStepInput === 1 ? 'next-input' : '',
                        'shadow-none km-input',
                        checkReadonly ? 'disabled' : '',
                      ]"
                      :disabled="checkReadonly"
                      @blur="setNextInputNumber"
                      @keypress="isNumber"
                      @keyup="removeFirstZero"
                    />
                    <p
                      class="input-suffix"
                      :class="[
                        curStepInput === 1 ? 'km-focus' : '',
                        checkReadonly ? 'disabled' : '',
                      ]"
                    >
                      km
                    </p>
                  </float-label>
                  <div class="fs-12 text-right mb-2 mt-n3 pr-2 err-text">
                    {{ kmsValidate }}
                  </div>
                </div>
              </template>
              <template v-slot:[generalConditionOrder.ownerManual]>
                <div v-if="!isNZ" class="px-3 col-md-6">
                  <custom-select
                    label="Owner's manual"
                    v-model="info.ownerManual"
                    :options="ownerManuals"
                    :error="$v.info.ownerManual.$error"
                    helperText="Please select your owner's manual"
                    name="ownerManual"
                    :isFocus="curStepInput === 2"
                    :selectDisabled="checkReadonly"
                    @setNextInput="setNextInputNumber"
                  />
                </div>
              </template>
              <template v-slot:[generalConditionOrder.numberOfKey]>
                <div class="px-3 col-md-6">
                  <custom-select
                    label="Number of keys"
                    v-model="info.key"
                    :options="keys"
                    :error="$v.info.key.$error"
                    helperText="Please select how many keys"
                    name="key"
                    :isFocus="curStepInput === 3"
                    :selectDisabled="checkReadonly"
                    @setNextInput="setNextInputNumber"
                    :optional="isNZ"
                  />
                </div>
              </template>
              <template v-slot:[generalConditionOrder.warranty]>
                <div v-if="isNZ" class="px-3 col-md-6 col-12">
                  <custom-select
                    label="Warrant of fitness"
                    v-model="info.warranty"
                    :options="warrantyOptions"
                    name="warranty"
                    :isFocus="curStepInput === 4"
                    :optional="true"
                    :selectDisabled="checkReadonly"
                  />
                </div>
              </template>
              <template v-slot:[generalConditionOrder.registrationStatus]>
                <div v-if="isNZ" class="px-3 col-md-6 col-12">
                  <custom-select
                    label="Registration status"
                    v-model="info.registrationStatus"
                    :options="registrationStatusOptions"
                    name="registrationStatus"
                    :optional="true"
                    :isFocus="curStepInput === 5"
                    @setNextInput="setNextInputNumber"
                    :selectDisabled="checkReadonly"
                  />
                </div>
              </template>
              <template v-slot:[generalConditionOrder.tyreReplacement]>
                <div class="px-3 col-md-6 col-12">
                  <custom-select
                    label="Tyres needing replacement"
                    v-model="info.tyreReplacement"
                    :options="tyreReplacements"
                    name="tyreReplacement"
                    :optional="true"
                    :isFocus="curStepInput === 6"
                    :selectDisabled="checkReadonly"
                    @setNextInput="setNextInputNumber"
                  />
                </div>
              </template>
              <template v-slot:[generalConditionOrder.serviceHistory]>
                <div class="px-3 col-md-6">
                  <custom-clear-select
                    label="Service history"
                    v-model="info.serviceHistory"
                    :options="serviceHistories"
                    :error="$v.info.serviceHistory.$error"
                    helperText="Please select your service history"
                    name="serviceHistory"
                    :ask="true"
                    :tooltip="serviceHistoryTooltip"
                    :isFocus="curStepInput === 7"
                    :selectDisabled="checkReadonly"
                    @setNextInput="setNextInputNumber"
                  />
                </div>
              </template>
              <template v-slot:[generalConditionOrder.serviceHistoryDetail]>
                <div class="px-3 col-md-6">
                  <custom-select
                    label="Service history details"
                    v-model="info.serviceHistoryDetail"
                    :options="serviceHistoryDetails"
                    :error="$v.info.serviceHistoryDetail.$error"
                    helperText="Please select your service history detail"
                    name="serviceHistoryDetail"
                    :selectDisabled="info.serviceHistory === '' || info.serviceHistory === 'None' || checkReadonly"
                    :isFocus="curStepInput === 8"
                    @setNextInput="setNextInputNumber"
                    :optional="isNZ"
                  />
                </div>
              </template>
              <template v-slot:[generalConditionOrder.serviceHistoryNotes]>
                <div class="px-3 col-md-6">
                  <custom-text-area
                    label="Service history notes"
                    v-if="isShowServiceHistoryNotes"
                    v-model="info.serviceHistoryNotes"
                    :rows="'3'"
                    :placeholder="serviceHistoryNotesPlaceholder"
                    :helperText="serviceHistoryNotesHelperText"
                    :error="$v.info.serviceHistoryNotes.$error"
                    name="serviceHistoryNotes"
                    :isFocus="curStepInput === 9"
                    :textareaDisabled="checkReadonly"
                    :optional="false"
                    @setNextInput="setNextInputNumber"
                    @keyup="validateServiceHistoryNotesMaxlength($event)"
                  />
                </div>
              </template>
            </OrderedComponents>
            <SectionDivider dividerText="CONDITION"></SectionDivider>
            <b-row>
              <b-col md="6" class="mt-3">
                <float-label
                  v-b-modal.modal-exterior
                  class="font-weight-bold fw-400 pb-2"
                  label="Exterior condition"
                  :class="`${checkReadonly ? 'float-label' : ''}`"
                ></float-label>
                <div style="max-width: 350px">
                  <b-form-rating
                    v-model="info.exterior"
                    :class="[
                      exteriorValidate ? 'bi-star-error' : '',
                      isMobile ? 'ml-1' : 'ml-4',
                      'mb-1 border-0 w-15 px-0 mt-n3 form-stars',
                    ]"
                    size="lg"
                    :disabled="checkReadonly"
                    @setNextInput="setNextInputNumber"
                  />
                  <div
                    class="mx-auto font-weight-regular mb-4"
                    v-if="info.exterior"
                  >
                    <p class="text-center fs-14 rate-label mb-1">
                      {{ exteriorRate[info.exterior - 1].text }}
                    </p>
                    <p class="fs-14 text-center mb-1">{{ exteriorRate[info.exterior - 1].desc }}</p>
                  </div>
                </div>
                <div class="fs-12 text-right mb-2 mt-n3 pr-2 err-text">
                  {{ exteriorValidate }}
                </div>
              </b-col>
              <b-col md="6" class="mt-3">
                <float-label
                  v-b-modal.modal-interior
                  class="font-weight-bold fw-400 pb-2"
                  label="Interior condition"
                  :class="`${checkReadonly ? 'float-label' : ''}`"
                ></float-label>
                <div style="max-width: 350px">
                  <b-form-rating
                    v-model="info.interior"
                    :class="[
                      interiorValidate ? 'bi-star-error' : '',
                      isMobile ? 'ml-1' : 'ml-4',
                      'mb-1 border-0 w-15 px-0 mt-n3 form-stars',
                    ]"
                    size="lg"
                    :disabled="checkReadonly"
                    @setNextInput="setNextInputNumber"
                  />
                  <div
                    class="mx-auto font-weight-regular mb-4"
                    v-if="info.interior"
                  >
                    <p class="text-center fs-14 rate-label mb-1">
                      {{ interiorRate[info.interior - 1].text }}
                    </p>
                    <p class="fs-14 text-center mb-1">{{ interiorRate[info.interior - 1].desc }}</p>
                  </div>
                </div>
                <div class="fs-12 text-right mb-2 mt-n3 pr-2 err-text">
                  {{ interiorValidate }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="px-3" :class="!isMobile && 'mt-4'">
                <custom-text-area
                  label="Car description - Damages and extras"
                  v-model="info.description"
                  :rows="isMobile ? '8' : '5'"
                  :error="$v.info.description.$error"
                  :helperText="conditionDescriptionHelperText"
                  name="description"
                  :isFocus="curStepInput === 10"
                  :textareaDisabled="checkReadonly"
                  :isCarDescription="true"
                  @setNextInput="setNextInputNumber"
                  @keyup="validateMaxlength($event)"
                />
              </b-col>
            </b-row>
            <SectionDivider dividerText="ADDITIONAL"></SectionDivider>
            <b-row>
              <b-col cols="12" md="6">
                <custom-toggle
                  v-model="info.outstandingFinance"
                  label="Do you have outstanding finance on this car?"
                  :error="$v.info.outstandingFinance.$error"
                  :infoTooltip="true"
                  :tooltip="financeTooltip"
                />
              </b-col>
              <b-col cols="12" md="6">
                <custom-toggle
                  v-model="info.majorAccident"
                  label="Has this car been in a prior major accident?"
                  :error="$v.info.majorAccident.$error"
                />
              </b-col>
              <b-col v-if="!isMobile" cols="12" md="6">
                <custom-toggle
                  v-model="info.warningLights"
                  label="Any warning lights/mechanical issues?"
                  :error="$v.info.warningLights.$error"
                  @change="handleOnChange($event)"
                />
              </b-col>
              <b-col cols="12" md="6">
                <custom-toggle
                  v-model="info.hailOrFlooding"
                  label="Has this car been damaged by a hailstorm/flooding?"
                  :error="$v.info.hailOrFlooding.$error"
                />
              </b-col>
              <b-col v-if="isMobile" cols="12" md="6">
                <custom-toggle
                  v-model="info.warningLights"
                  label="Any warning lights/mechanical issues?"
                  :error="$v.info.warningLights.$error"
                  @change="handleOnChange($event)"
                />
              </b-col>
            </b-row>
            <b-row v-if="info.warningLights === 'Yes'">
              <b-col md="6" class="px-3 mt-4">
                <custom-text-area
                  label="Warning lights/mechanical issues notes"
                  v-model="info.warningLightsDesc"
                  :rows="isMobile ? '8' : '5'"
                  :placeholder="warningLightDescPlaceholder"
                  :error="$v.info.warningLightsDesc.$error"
                  :helperText="warningLightsDescHelperText"
                  name="warningLightDesc"
                  :textareaDisabled="checkReadonly"
                  @keyup="validateWarningLightsMaxlength($event)"
                />
              </b-col>
            </b-row>
            <div class="container-question mt-4 text-left">
              <div class="d-flex justify-content-between align-items-center item">
                <u 
                  aria-controls="report-issue"
                  :aria-expanded="isActiveReportIssue"
                  @click="activeReportIssue()"
                  class="mb-0 cursor-pointer"
                >
                  Need to report an issue?
                </u>
                <span @click="activeReportIssue()" id="quest-2">
                  <img
                    aria-controls="report-issue"
                    :aria-expanded="isActiveReportIssue"
                    :src="require('@/assets/images/icons/ic_arrow_down.svg')"
                    :style="isActiveReportIssue ? 'transform: rotate(180deg)' : ''"
                  />
                </span>
              </div>
              <b-collapse id="report-issue" v-model="isActiveReportIssue">
                <p class="quick-answer font-weight-thin">
                  If you experience any issues with what you see on this page, please contact the {{ brandName }} team.<br>
                  <u v-b-modal.modal-issue style="cursor: pointer;">Report an issue</u>
                </p>
              </b-collapse>
            </div>
          </div>
          <div style="height: 40px;"></div>
          <btn-group
            @onSubmit="next"
            @onCancel="goBack"
            @onSave="openDialog"
            submitText="Confirm"
            :disableSubmit="!isValid && !checkReadonly"
            :query=this.$route.query
            :isMobile="isMobile"
          />
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  CLEAR_SERVICE_HISTORY_DETAIL,
  CLEAR_SERVICE_HISTORY_NOTES,
  UPDATE_DATA,
} from "@/store/authenticate-seller/actions";
import { SET_CONDITION_INFO } from "@/store/authenticate-seller/actions";
import regex, { formatNumberWithComma } from "../../helper/utils";
import mixin from "@/mixins/common";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import { EXTERIOR_RATE, INTERIOR_RATE } from "@/config/constants";

export default {
  name: "Index",
  mixins: [mixin],
  components: {
    CustomSelect: () => import("../../components/common/CustomSelect"),
    CustomClearSelect: () => import("../../components/common/CustomClearSelect"),
    BtnGroup: () => import("../../components/common/BtnGroup.vue"),
    FloatLabel: () => import("../../components/common/FloatLabel.vue"),
    CustomTextArea: () => import("../../components/common/CustomTextArea.vue"),
    CustomToggle: () => import("../../components/common/CustomToggle.vue"),
    SectionDivider: () => import("../../components/common/SectionDivider.vue"),
    CustomDialog: () => import("../../components/common/CustomDialog"),
    ListedCarMessage: () => import("../../components/common/ListedCarMessage.vue"),
    OrderedComponents: () => import("../../components/common/OrderedComponents.vue"),
    ReminderPopup: () => import("../../components/condition-info/ReminderPopup"),
  },
  data() {
    return {
      submitted: false,
      serviceHistoryNotesPlaceholder:
        "Provide additional information about partial service history - reasons and number of services missing, etc.",
      exteriorRate: [...EXTERIOR_RATE],
      interiorRate: [...INTERIOR_RATE],
      curStepInput: 1,
      inputStep: [
        {
          step: 1,
          key: "kms",
        },
        {
          step: 2,
          key: "key",
        },
        {
          step: 3,
          key: "ownerManual",
        },
        {
          step: 4,
          key: "serviceHistory",
        },
        {
          step: 5,
          key: "interior",
        },
        {
          step: 6,
          key: "exterior",
        },
        {
          step: 7,
          key: "outstandingFinance",
        },
        {
          step: 8,
          key: "majorAccident",
        },
        {
          step: 9,
          key: "hailOrFlooding",
        },
        {
          step: 10,
          key: "warningLights",
        },
        {
          step: 11,
          key: "",
        },
      ],
      tyreReplacements: [
        "No replacement",
        "One tyre",
        "Two tyres",
        "Three tyres",
        "Four tyres",
      ],
      isMobile: true,
      serviceHistoryTooltip: {
        Full:
          " - You must have evidence of complete and accurate records of service history from purchase until date. It must be stamped or invoiced by a franchise dealer or independent service agent.",
        Partial:
          " - Some records of service history are missing. You must have evidence of the records you have.",
      },
      regoInfo: this.$store.getters.regoSearch,
      isDialogOpen: false,
      isCurrPageChanges: false,
      isActiveReportIssue: true,
      warningLightDescPlaceholder:
        "Provide additional details about your car's warning lights/mechanical issues.",
      registrationStatusOptions: [
        "NZ new",
        "Imported",
      ],
      financeTooltip:
        "There is an existing loan or financial obligation associated with it. If you borrowed funds from family for this car, it's not categorised as financed.",
      isReminderOpen: false,
    };
  },
  computed: {
    ...mapState({
      info: (state) => state.authenticateSeller.conditionInfo,
      steps: (state) => state.common.steps,
      authenticateData: (state) => state.authenticateSeller,
      stateImgs: (state) => state.photo.uploadImage,
      isIdle: (state) => state.common.isIdle,
      reminderPopupWasShown: (state) => state.common.reminderPopupWasShown,
      brand: (state) => state.commonVariables.brandName,
    }),
    isValid: function () {
      return !this.$v.info.$invalid;
    },
    keys: function () {
      return ["1", "2", "3 or more"];
    },
    ownerManuals: function () {
      return ["Yes", "No"];
    },
    serviceHistories: function () {
      return ["Full", "Partial", "None"];
    },
    kmsValidate: function () {
      if (this.submitted && !this.info.kms) return "Please enter kms";
      //eslint-disable-next-line
      if (this.submitted && regex("^-?[d,]+$", this.info.kms))
        return "Please enter only numerics";
      return "";
    },
    exteriorValidate: function () {
      if (this.submitted && this.info.exterior === null)
        return "Please select exterior condition";
      return "";
    },
    interiorValidate: function () {
      if (this.submitted && this.info.interior === null)
        return "Please select interior condition";
      return "";
    },
    mobileMediaQuery: function() {
      return window.matchMedia("(min-width: 768px)");
    },
    conditionDescriptionHelperText: function () {
      if (this.info.description.length > 2000) {
        return 'Please enter less than 2,000 characters';
      } else {
        return 'Please enter more detail';
      }
    },
    checkReadonly: function() {
      return this.authenticateData.listed || this.authenticateData.readonlyMode;
    },
    warningLightsDescHelperText: function() {
      if (this.info.warningLightsDesc.length > 100) {
        return 'Please enter less than 100 characters';
      } else {
        return 'Please enter more detail';
      }
    },
    isShowServiceHistoryNotes() {
      return this.info.serviceHistory === 'Partial' && !this.isNZ
    },
    serviceHistoryNotesHelperText: function () {
      return this.info.serviceHistoryNotes !== ""
        ? "Please enter less than 100 characters"
        : "Please enter more detail";
    },
    isNZ: function() {
      return parseInt(this.authenticateData.locid) === 2;
    },
    serviceHistoryDetails: function () {
      return this.isNZ
        ? ["Franchise service history", "Other"]
        : [
            {
              key: "Genuine - with a franchise dealer",
              value: "Genuine",
            },
            {
              key: "Non-genuine - with an independent authorised service agent",
              value: "Non-genuine",
            },
            {
              key: "Mixed",
              value: "Mixed",
            },
          ];
    },
    warrantyOptions: function() {
      return ["Yes", "No"];
    },
    generalConditionOrder: function () {
      return this.isNZ
        ? {
            odometer: "1",
            serviceHistory: "2",
            serviceHistoryDetail: "3",
            numberOfKey: "4",
            warranty: "5",
            registrationStatus: "6",
            tyreReplacement: "7",
            serviceHistoryNotes: "8",
            ownerManual: "9",
          }
        : {
            odometer: "1",
            ownerManual: "2",
            numberOfKey: "3",
            warranty: "4",
            registrationStatus: "5",
            tyreReplacement: "6",
            serviceHistory: "7",
            serviceHistoryDetail: "8",
            serviceHistoryNotes: "9",
          };
    },
    brandName() {
      return this.brand || "Autoflip";
    },
  },
  methods: {
    validateMaxlength: function (value) {
      this.$v.info.description.$reset();
      if (value.length > 2000) {
        this.info.description = value;
        this.$v.info.description.$touch();
      }
    },
    validateServiceHistoryNotesMaxlength: function (value) {
      this.$v.info.serviceHistoryNotes.$reset();
      if (value.length > 100) {
        this.info.serviceHistoryNotes = value;
        this.$v.info.serviceHistoryNotes.$touch();
      }
    },
    next: async function () {
      if (!this.checkReadonly) {
        this.submitted = true;
        this.$v.info.$touch();
      }
      if (this.isValid || this.checkReadonly) {
        await this.$store.dispatch(SET_CONDITION_INFO, {
          ...this.info,
        });
        if (!this.reminderPopupWasShown) {
          this.openReminder();
          this.$store.dispatch('setReminderPopupWasShown', true);
        } else {
          this.$router.push({ name: "navigation", query: this.$route.query });
        }
      }
    },
    goBack: async function() {
      await this.$store.dispatch(SET_CONDITION_INFO, {
        ...this.info,
      });
      this.$router.push({ name: "navigation", query: this.$route.query });
    },
    setNextInputNumber() {
      for (const item of this.inputStep) {
        if (!this.info[item.key] || item.key === "") {
          this.curStepInput = item.step;
          break;
        }
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode >= 48 &&
        charCode <= 57 &&
        !(charCode === 48 && this.info.kms === "")
      ) {
        return true;
      } else {
        evt.preventDefault();
        return false;
      }
    },
    removeFirstZero() {
      if (/^0/.test(this.info.kms)) {
        this.info.kms = this.info.kms.replace(/^0+/, "");
      }
      this.isCurrPageChanges = true;
    },
    openDialog() {
      this.isDialogOpen = true;
    },
    closeDialog() {
      this.isDialogOpen = false;
    },
    handleBeforeUnload(event) {
      if (!this.isIdle) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    async saveOrUpdateTempData() {
      this.$store.dispatch(UPDATE_DATA, this.authenticateData);
    },
    activeReportIssue() {
      this.isActiveReportIssue = !this.isActiveReportIssue;
    },
    openJiraIssue: function(e) {
      e.preventDefault();
      window.showCollectorDialog();
    },
    validateWarningLightsMaxlength: function (value) {
      this.$store.dispatch(SET_CONDITION_INFO, {
        ...this.info,
        warningLightsDesc: value,
      });
      this.$v.info.warningLightsDesc.$reset();
      if (value.length > 100) {
        this.$v.info.warningLightsDesc.$touch();
      }
    },
    handleOnChange: function (value) {
      this.$store.dispatch(SET_CONDITION_INFO, {
        ...this.info,
        warningLights: value,
      });
    },
    openReminder() {
      this.isReminderOpen = true;
    },
    closeReminder() {
      this.isReminderOpen = false;
    },
  },
  validations: {
    info: {
      kms: {
        required,
      },
      ownerManual: {
        required: function() {
          return this.isNZ || this.info.ownerManual !== '';
        },
      },
      serviceHistory: {
        required,
      },
      exterior: {
        required,
      },
      interior: {
        required,
      },
      key: {
        required: function() {
          return this.isNZ || this.info.key !== '';
        },
      },
      description: {
        required,
        maxLength: maxLength(2000),
      },
      outstandingFinance: {
        required,
      },
      majorAccident: {
        required,
      },
      hailOrFlooding: {
        required,
      },
      warningLights: {
        required,
      },
      serviceHistoryDetail: {
        required: function () {
          return (
            this.info.serviceHistory === "None" ||
            this.info.serviceHistoryDetail !== "" ||
            this.isNZ
          );
        },
      },
      warningLightsDesc: {
        required: function() {
          return this.info.warningLights !== 'Yes' || this.info.warningLightsDesc !== ''
        },
        minlength: minLength(10),
        maxLength: maxLength(100),
      },
      serviceHistoryNotes: {
        required: function () {
          return (
            this.info.serviceHistory !== "Partial" ||
            this.info.serviceHistoryNotes !== "" ||
            this.isNZ
          );
        },
        maxLength: maxLength(100),
      },
    },
  },
  watch: {
    "info.interior": function (val) {
      if (val) {
        this.curStepInput = 6;
        this.isCurrPageChanges = true;
        this.$store.dispatch('setOnEditPage', true);
      }
    },
    "info.exterior": function (val) {
      if (val) {
        this.curStepInput = 5;
        this.isCurrPageChanges = true;
        this.$store.dispatch('setOnEditPage', true);
      }
    },
    "info.outstandingFinance": function(val) {
      if (val) {
        this.curStepInput = 7
        this.isCurrPageChanges = true;
        this.$store.dispatch('setOnEditPage', true);
      }
    },
    "info.majorAccident": function(val) {
      if (val) {
        this.curStepInput = 8
        this.isCurrPageChanges = true;
        this.$store.dispatch('setOnEditPage', true);
      }
    },
    "info.hailOrFlooding": function(val) {
      if (val) {
        this.curStepInput = 9
        this.isCurrPageChanges = true;
        this.$store.dispatch('setOnEditPage', true);
      }
    },
    "info.warningLights": function(val) {
      if (val) {
        this.curStepInput = 10
        this.isCurrPageChanges = true;
        this.$store.dispatch('setOnEditPage', true);
      }
    },
    curStepInput: function () {
      let progress = 0;
      for (const item of this.inputStep) {
        if (this.info[item.key] && item.key !== "") {
          progress = progress + 10 / (this.inputStep.length - 1);
        }
      }
    },
    "info.kms": function(val, prevVal) {
      this.$nextTick(
        () => (this.info.kms = formatNumberWithComma(this.info.kms))
      );
      if (prevVal.replace(/,/g, '') !== val.replace(/,/g, '')) {
        this.$store.dispatch('setOnEditPage', true);
      }
    },
    "info.serviceHistory": function(val) {
      if (val === 'None') {
        this.$store.dispatch(CLEAR_SERVICE_HISTORY_DETAIL);
        this.isCurrPageChanges = true;
      }
      if (val === 'Partial' && this.info.serviceHistoryNotes === ' ') {
        this.$store.dispatch(CLEAR_SERVICE_HISTORY_NOTES);
      }
      this.$store.dispatch('setOnEditPage', true);
    },
    "info.ownerManual": function() {
      this.isCurrPageChanges = true;
      this.$store.dispatch('setOnEditPage', true);
    },
    "info.serviceHistoryDetail": function() {
      this.isCurrPageChanges = true;
      this.$store.dispatch('setOnEditPage', true);
    },
    "info.key": function() {
      this.isCurrPageChanges = true;
      this.$store.dispatch('setOnEditPage', true);
    },
    "info.tyreReplacement": function() {
      this.isCurrPageChanges = true;
      this.$store.dispatch('setOnEditPage', true);
    },
    "info.description": function() {
      this.isCurrPageChanges = true;
      this.$store.dispatch('setOnEditPage', true);
    },
    "info.warningLightsDesc": function() {
      this.$store.dispatch('setOnEditPage', true);
    },
    "info.serviceHistoryNotes": function() {
      this.$store.dispatch('setOnEditPage', true);
    },
  },
  mounted() {
    this.$nextTick(
      () => (this.info.kms = formatNumberWithComma(this.info.kms))
    );

    // Check mobile screen
    this.mobileMediaQuery.addListener((e) => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });

    this.isMobile = !this.mobileMediaQuery.matches;

    // Check is close tab
    window.addEventListener('beforeunload', this.handleBeforeUnload);

    this.$store.dispatch('setOnEditPage', false);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
};
</script>

<style lang="scss">
.form-stars {
  .b-rating-star {
    padding: 0 10px !important;
    z-index: 9;
  }
  .b-rating-icon {
    font-size: 40px;
  }
}

.content {
  margin: 0 auto;
  text-align: left;
  min-height: calc(100vh - 179px);
}
.auto-info {
  background-color: #f2f2f2;
  border-color: #b3b3b3;
}
.next-input {
  color: #2c2448;
  background-color: #fff;
  border-color: var(--primary-color);
  outline: 0;
  &.disabled {
    border-color: transparent !important;
  }
}
.km-input:focus + .input-suffix {
  color: var(--primary-color);
  border-color: var(--primary-color);
}
.input-suffix {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 65px;
  text-align: center;
  padding: 13px 10px;
  border-left: 1px solid #ced4da;
  color: #989898;
  &.disabled {
    color: #989898 !important;
    border-color: #989898 !important;
  }
}
.km-focus {
  color: var(--primary-color);
  border-color: var(--primary-color);
}
.car-condition {
  .b-rating.disabled, .b-rating:disabled {
    background-color: #ffffff !important;
  }
  .listed-message-banner {
    margin: 20px 18px 15px 18px;
  }
  .card {
    border: none !important;
    .card-body {
      padding: 0 8px 0 !important;
    }
  }
  .btn-group-wrapper {
    .action-btn {
      border: 1px solid var(--primary-color);
    }
  }
}
.rate-label {
  color: var(--sub-header-text-color);
  text-align: center;
  text-transform: uppercase;
}
.my-n3 {
  margin-top: -1.25rem !important;
}
.font-weight-bold {
  font-weight: 400 !important;
}
.bi-star-error {
  svg {
    path {
      fill: red;
    }
  }
}

.vehicle-condition-item {
  // padding-top: 25px;

  .star-rating {
    height: fit-content;
    padding: 0;
    margin-top: 6px;
    width: 70%;

    &:focus {
      box-shadow: none;
    }

    span.b-rating-star {
      padding: 0 4px;

      &:first-child {
        padding-left: 0;
      }

      svg {
        path {
          fill: #2bb673;
        }
        width: 35px;
        height: 35px;
      }
    }

    .b-rating-star-full {
      color: var(--star-rating-color);
    }
  }
}
#modal-exterior,
#modal-interior {
  &.modal {
    padding-left: unset !important;
  }
  .modal-header {
    border-bottom: 0;
    padding: 1.6rem 1rem 0;
    .close {
      color: #00c44b !important;
    }
  }
  .modal-title {
    font-weight: bold;
  }
  .modal-body {
    padding-top: 0 !important;
  }
  .modal-content {
    border-radius: 1rem;
    border: 4px solid #2bb673;
  }
  hr {
    background-color: #c4c4c4 !important;
  }
}

.content-title {
  color: var(--primary-color);
}

@media screen and (min-width: 768px) {
  .car-condition {
    .card {
      padding: 1rem 3.5rem 1.5rem !important;
    }
  }
  .answer-group {
    .answer {
      min-width: 70px !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .car-condition {
    .card {
      padding: 0 10px 24px !important;
    }
  }
}

// Tablet
@media screen and (min-width: 768px ) and (orientation: portrait) {
  .form-stars {
    .b-rating-icon {
      font-size: 37px;
    }
  }
  #modal-exterior,
  #modal-interior {
    .modal-dialog {
      top: -10% !important;
    }
    .modal-dialog {
      max-width: 777px;
    }
  }
  .form-stars,
  .rate-label-wrapper {
    margin-left: 0.5rem !important;
  }
}

@media screen and(min-width: 768px) and(max-width: 1200px) {
  .car-condition {
    .listed-message-banner {
      margin-left: 60px;
      margin-right: 60px;
    }
  }
}

@media screen and(min-width: 1200px) {
  .car-condition {
    .listed-message-banner {
      margin-left: 75px;
      margin-right: 75px;
    }
    .btn-group-wrapper {
      padding-left: 100px;
      padding-right: 100px;
    }
    .base-width {
      max-width: 955px;
    }
  }
}
</style>
