<template>
  <label 
    class="form-group border-lable-flt"
    :class="isBottomAlign ? 'mb-1' : 'mb-3'"
    v-click-outside="blurTooltip"
  >
    <slot></slot>
    <span>
      {{ label }}{{ optional ? " (Optional)" : "" }}
      <img
        v-if="ask"
        class="question-mark"
        :src="require(`@/assets/images/icons/ic_question_mark_black_filled.svg`)"
        alt="question-mark"
        @click="toggleTooltip"
        v-on:mouseenter="hoverTooltip"
        v-on:mouseleave="blurTooltip"
      />
      <i v-if="haveTooltip" class="arrow" ref="tooltipArrow"></i>
    </span>
    <div
      ref="floatLabelTooltip"
      class="float-label-tooltip shadow-lg font-weight-regular fs-12"
    >
      <p class="mb-0">{{ tooltip }}</p>
    </div>
  </label>
</template>
<script>
export default {
  name: "FloatLabel",
  data() {
    return {
      isMobile: true,
    }
  },
  props: {
    label: {
      type: String,
      default: () => "",
    },
    optional: {
      type: Boolean,
      default: () => false,
    },
    ask: {
      type: Boolean,
      default: () => false,
    },
    tooltip: {
      type: String,
      default: () => "",
    },
    isBottomAlign: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    mobileMediaQuery: function() {
      return window.matchMedia("(min-width: 768px)");
    },
    haveTooltip: function() {
      return Object.keys(this.tooltip).length !== 0
    },
  },
  methods: {
    toggleTooltip: function(e) {
      e.preventDefault();
      e.stopPropagation();
      if (this.haveTooltip && this.isMobile) {
        const currentVisibility = this.$refs.floatLabelTooltip.style.visibility;
        this.$refs.floatLabelTooltip.style.visibility =
          currentVisibility === "visible" ? "hidden" : "visible";
        this.$refs.tooltipArrow.style.visibility =
          currentVisibility === "visible" ? "hidden" : "visible";
      }
    },
    blurTooltip: function() {
      if (this.haveTooltip) {
        this.$refs.floatLabelTooltip.style.visibility = "hidden";
        this.$refs.tooltipArrow.style.visibility = "hidden";
      }
    },
    hoverTooltip: function() {
      if (this.haveTooltip && !this.isMobile) {
        this.$refs.floatLabelTooltip.style.visibility = "visible";
        this.$refs.tooltipArrow.style.visibility = "visible";
      }
    },
  },
  mounted() {
    // Check mobile screen
    this.mobileMediaQuery.addListener((e) => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });

    this.isMobile = !this.mobileMediaQuery.matches;
  },
};
</script>
<style lang="scss">
.float-label-tooltip {
  width: 252px;
  position: absolute;
  bottom: 60px;
  left: 70px;
  padding: 10px;
  border-radius: 4px;
  background-color: #2E312E;
  color: white;
  text-align: left;
  visibility: hidden;
  z-index: 10;

  p:last-child {
    margin-bottom: 0;
  }
}

@media screen and(min-width: 768px) {
  .question-mark {
    cursor: pointer;
  }
}
</style>
